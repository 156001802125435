import React, { useState, useEffect, useRef } from "react";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { Link, StaticQuery, graphql, useStaticQuery } from "gatsby";

const Dropdown = (props) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);
  return (
    <div className={`relative ${props.className}`}>
      <button
        type="button"
        onClick={() => setIsActive(!isActive)}
        className={` group  inline-flex items-center space-x-2 text-base leading-6 font-medium  focus:outline-none  transition ease-in-out duration-150 ${
          props.isDark ? "text-gray-300 hover:text-white" : "text-gray-600 hover:text-gray-900"
        }`}>
        <span>{props.label}</span>

        <svg
          className="text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <div
        className={`absolute transform mt-3 px-2 w-screen max-w-sm sm:px-0 ${
          isActive ? "" : "hidden"
        }`}
        ref={dropdownRef}>
        <div className="rounded-lg shadow-lg">
          <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
              {props.options?.map((e) => (
                <Link to={`${e.link}`}>
                  <div className="-m-3 p-5 block space-y-1 rounded-md hover:bg-gray-100 transition ease-in-out duration-150">
                    {e.icon ? (
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-50 text-white sm:h-12 sm:w-12">
                        <img src={e.icon} />
                      </div>
                    ) : (
                      ""
                    )}
                    <p className="text-base leading-6 font-medium text-gray-900">
                      {e.title}
                    </p>
                    <p className="text-sm leading-5 text-gray-500">
                      {e.description}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
